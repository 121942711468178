<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <div class="card-title">Teamkalendar</div>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            Einstellungen
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Zeitspanne"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
                <v-btn color="primary" tile @click="updateCalendar">Übernehmen</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            Legende
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <i class="material-icons">person_add_disabled</i> Krank
                    </td>
                    <td>
                      <i class="material-icons">family_restroom</i> Mutterschutz
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i class="material-icons">star</i>Urlaub
                    </td>
                    <td>
                      <i class="material-icons">star_border</i>Urlaub beantragt
                    </td>
                  </tr>
                  <!--  <tr>
                    <td>
                      <div class="weekend-legend"></div>Wochenende
                    </td>
                    <td>
                      <div class="holiday-legend"></div>Feiertag
                    </td>
                  </tr>-->
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" xs="12" sm="8" md="4" lg="3"></v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-center"
                      v-for="(head, i) in headers"
                      :key="i"
                      v-html="head.text"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,i) in items" :key="i">
                    <td
                      style="position:relative"
                      v-for="(entry,j) in Object.keys(item)"
                      :key="j"
                      v-html="getString(entry,item)"
                      @click="onCellClick(entry,j)"
                    ></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      confirm: false,
      dates: [],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      year: "",
      monthname: "",
      search: "",
      items: [],
      supervisors: [],
      monthnames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
      ],
      daynames: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      headers: [],
      rules: {
        required: value => !!value || "Required.",
        email: [
          value => !!value || "Erforderlich.",
          v => /.+@.+/.test(v) || "E-Mail ist nicht korrekt"
        ]
      }
    };
  },
  computed: {
    dateRangeText() {
      const fromDate = this.$formatDate(this.dates[0]);
      const toDate = this.$formatDate(this.dates[1]);
      return fromDate + " - " + toDate;
    }
  },
  mounted() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);
    this.dates.push(this.$formatDateISO(firstDay));
    this.dates.push(this.$formatDateISO(lastDay));

    this.createCalendar(firstDay, lastDay);
    this.getMembers();
  },
  methods: {
    updateCalendar() {
      const firstDay = new Date(this.dates[0]);
      const lastDay = new Date(this.dates[1]);
      this.createCalendar(firstDay, lastDay);
    },
    saveDate() {
      this.$refs.menu.save(this.date);
    },
    getString(entry, item) {
      if (!item[entry]) {
        return "";
      }
      if (entry === "name") {
        return item.name;
      }
      if (item[entry] === "k") {
        return "<i class='material-icons'>person_add_disabled</i>";
      }
      if (item[entry] === "m") {
        return "<i class='material-icons'>family_restroom</i>";
      }
      if (item[entry] === "w") {
        return "<div class='weekend-td'></div>";
      }
      if (item[entry] === "f") {
        return "<div class='holiday-td'></div>";
      }
      if (item[entry] === "u") {
        return "<i class='material-icons'>star</i>";
      }
      if (item[entry] === "bu") {
        return "<i class='material-icons'>star_border</i>";
      }
      return item[entry];
    },
    onCellClick(item, index) {
    },
    createCalendar(firstDay, lastDay) {
      this.headers = [];
      this.headers.push({
        text: "Mitarbeitername",
        sortable: true,
        value: "name"
      });
      //const days = this.$diffInDays(firstDay, lastDay);

      while (firstDay <= lastDay) {
        let day = firstDay.getDay();
        this.headers.push({
          text:
            "<span class='dayname'>" +
            this.daynames[day % 7] +
            "</span><div class='datenumber'> " +
            firstDay.getDate() +
            "." +
            (firstDay.getMonth() + 1) +
            "</div>",
          sortable: false,
          value: "day" + firstDay.getDate()
        });
        firstDay.setDate(firstDay.getDate() + 1);
      }
      /* let startDay = firstDay.getDay();
      for (let index = 1; index <= days; index++) {
        this.headers.push({
          text: this.daynames[startDay % 7] + "<span class='datenumber'>" + index + "</span>",
          sortable: false,
          value: "day" + index
        });
        startDay++;
      } */
    },
    getMembers() {
      const data = [
        {
          name: "Milad Saber",
          day1: "",
          day2: "u",
          day3: "u",
          day4: "u",
          day5: "u",
          day6: "BKK",
          day7: "",
          day8: "BKK",
          day9: "BKK",
          day10: "BKK",
          day11: "k",
          day12: "Brillux",
          day13: "Brillux",
          day14: "Brillux",
          day15: "",
          day16: "",
          day17: "",
          day18: "Brillux",
          day19: "w",
          day20: "w",
          day21: "",
          day22: "",
          day23: "",
          day24: "f",
          day25: "f",
          day26: "f",
          day27: "w",
          day28: "",
          day29: "",
          day30: "",
          day31: ""
        },
        {
          name: "Max Muster",
          day1: "Brillux",
          day2: "Brillux",
          day3: "Brillux",
          day4: "Brillux",
          day5: "w",
          day6: "w",
          day7: "bu",
          day8: "bu",
          day9: "bu",
          day10: "k",
          day11: "k",
          day12: "w",
          day13: "w",
          day14: "u",
          day15: "Brillux",
          day16: "",
          day17: "",
          day18: "u",
          day19: "w",
          day20: "w",
          day21: "",
          day22: "",
          day23: "",
          day24: "f",
          day25: "f",
          day26: "f",
          day27: "w",
          day28: "",
          day29: "",
          day30: "",
          day31: ""
        },
        {
          name: "Bruce Wayne",
          day1: "",
          day2: "BKK",
          day3: "BKK",
          day4: "u",
          day5: "u",
          day6: "BKK",
          day7: "",
          day8: "BKK",
          day9: "BKK",
          day10: "BKK",
          day11: "k",
          day12: "Brillux",
          day13: "Brillux",
          day14: "Brillux",
          day15: "",
          day16: "",
          day17: "BKK",
          day18: "Brillux",
          day19: "w",
          day20: "w",
          day21: "u",
          day22: "u",
          day23: "",
          day24: "f",
          day25: "f",
          day26: "f",
          day27: "w",
          day28: "",
          day29: "",
          day30: "",
          day31: ""
        }
      ];
      this.items = data;
    },
    openDialog() {
      if (!this.supervisors.length) {
        this.$http({
          method: "post",
          url: "getSupervisors.php"
        })
          .then(response => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then(resp => {
                  this.$router.push("/login");
                })
                .catch(err => {
                  console.log(err);
                });
            }
            if (response.data && response.data.success && response.data.data) {
              this.supervisors = response.data.data;
            }
            this.dialog = true;
          })
          .catch(function(error) {
            this.dialog = true;
            console.log(error);
          });
      } else {
        this.dialog = true;
      }
    },
    confirmDeleteItem(id, name) {
      this.deleteName = name;
      this.deleteId = id;
      this.confirm = true;
    }
  }
};
</script>